import { headerHeight } from '../../components/Header/styles';
import { styled } from '../../styles/global';

export const Wrapper = styled('main', {
  display: 'flex',
  flexDirection: 'column',
});

export const Content = styled('section', {
  width: '100%',
  minHeight: `calc(100vh - ${headerHeight})`,

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  gap: '3.875rem', // 62px

  '@large': {
    marginTop: '4.225rem', // 67.6px
    marginBottom: '4.938rem', // 79px

    flexWrap: 'wrap',
  },

  '@medium': {
    flexDirection: 'column',

    width: '90%',
    marginX: 'auto',
  },
});

export const Card = styled('a', {
  width: '100%',
  maxWidth: '21.688rem', // 347px

  minHeight: '31.813rem', // 509px

  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',

  gap: '0.375rem', // 6px

  borderRadius: '1.563rem', // 25px
  boxShadow: '$card',

  backgroundColor: '$primary',

  transition: 'background 0.5s ease',

  '&:hover, &:active': {
    backgroundColor: '$orange',
  },

  color: '$white',

  fontWeight: '$regular',
  fontSize: '2.188rem', // 35px
  lineHeight: 'normal',

  userSelect: 'none',

  '@small': {
    maxWidth: '16.625rem', // 266px

    fontSize: '1.75rem', // 28px
  },

  img: {
    pointerEvents: 'none',

    width: '100%',
    height: '100%',

    maxWidth: '14.063rem', // 225px
    maxHeight: '14.063rem', // 225px
  },
});
