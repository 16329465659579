import { styled } from '../../styles/global';

export const Logo = styled('img', {
  width: 'minmax(16.7rem, fit-content)',
  height: '2.5rem',

  objectFit: 'contain',
  objectPosition: 'center',

  '@medium': {
    width: 'minmax(12.5rem, fit-content)',
    height: '2rem',

    marginTop: '1.5rem',
  },
});
