import { createTheme } from '@stitches/react';

const colorsBase = {
  // main colors
  primary: '#0062DC',
  white: '#ffffff',
  black: '#181921',

  // secondary colors
  cyan: '#00D4FF',
  orange: '#FC6D20',
  graphite: '#343647',

  pureBlack: '#000',
  buttonBorder: '#91B1DD',
};

export const lightColors = {
  ...colorsBase,
};

export const darkColors = {
  ...colorsBase,
};

const defaultColors = lightColors;

export const theme = {
  colors: defaultColors,
  fonts: {
    base: "'Open Sans', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
    code: "'Open Sans', source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace, sans-serif",
  },
  fontStyles: {
    normal: 'normal',
    italic: 'italic',
  },
  fontWeights: {
    regular: 400,
    medium: 500,
    bold: 700,
  },
  borderRadius: {
    sm: '0.4rem', // 4px
    md: '0.8rem', // 8px
    lg: '1.6rem', // 16px
    circular: '50%',
    full: '99999px',
  },
  shadows: {
    header: '0px 4px 20px 0px rgba(0, 0, 0, 0.15)',
    card: '4px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  },
} as const;

export const darkTheme = createTheme({
  colors: darkColors,
});
