import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import backIcon from '../assets/backIcon.svg';
import closeEyeIcon from '../assets/eye-off.svg';
import openEyeIcon from '../assets/eye.svg';
import {
  ButtonShowPassword,
  Code,
  Email,
  Password,
} from '../components/authComponents';
import { BackgroundImage, useStyles } from '../components/styleComponents';
import { AuthContext } from '../contexts/authContext';
import { useDocumentTitle } from '../hooks/use-document-title';
import {
  usePasswordIsVisible,
  useValidCode,
  useValidEmail,
  useValidPassword,
} from '../hooks/useAuthHooks';
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from '../utils/constants';

export const ForgotPassword = () => {
  const classes = useStyles();

  const { code, setCode, codeIsValid } = useValidCode('');
  const { password, setPassword, passwordIsValid, passwordLabel } =
    useValidPassword('');
  const { email, setEmail, emailIsValid } = useValidEmail('');

  const {
    password: passwordConfirm,
    setPassword: setPasswordConfirm,
    passwordIsValid: passwordConfirmIsValid,
  } = useValidPassword('');

  const { passwordIsVisible, setPasswordIsVisible } = usePasswordIsVisible();

  const isValid =
    !codeIsValid ||
    code.length === 0 ||
    !emailIsValid ||
    email.length === 0 ||
    !passwordIsValid ||
    password.length === 0 ||
    !passwordConfirmIsValid ||
    passwordConfirm.length === 0;

  const history = useHistory();

  const authContext = useContext(AuthContext);

  const resetPassword = async () => {
    try {
      await authContext.forgotPassword(email, code, password);
      toast.success(SUCCESS_MESSAGES.resetPassword);
      history.push('/signin');
    } catch (err) {
      toast.error(ERROR_MESSAGES.resetPassword);
    }
  };

  useDocumentTitle('Esqueceu sua senha?');

  return (
    <Grid
      className={classes.root}
      container
      direction="row"
      alignItems="center"
    >
      <button
        type="button"
        className="backIcon"
        onClick={() => history.goBack()}
      >
        <img src={backIcon} alt="ícone que representa uma seta voltando" />
      </button>
      <BackgroundImage />
      <Grid
        xs={11}
        sm={6}
        lg={6}
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        item
        style={{ marginBottom: '100px', margin: '0 auto' }}
      >
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          style={{ maxWidth: '700px' }}
        >
          {/* Title */}
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              variant="h5"
              style={{
                fontWeight: 'bold',
                marginTop: '15px',
                marginBottom: '20px',
              }}
            >
              Escolha uma nova senha
            </Typography>
          </Grid>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              style={{
                textAlign: 'center',
                width: '65%',
                marginTop: '15px',
                marginBottom: '20px',
              }}
            >
              Pegue o código enviado para seu email e confirme sua nova senha:
            </Typography>
          </Grid>

          {/* Sign In Form */}
          <Box width="80%" m={1} className="code-container">
            <Code codeIsValid={codeIsValid} setCode={setCode} />
          </Box>
          <Box width="80%" m={1}>
            <Email emailIsValid={emailIsValid} setEmail={setEmail} />
          </Box>
          <Box width="80%" m={1} className="password-container">
            <Password
              label="Senha"
              passwordIsValid={passwordIsValid}
              setPassword={setPassword}
              passwordIsVisible={passwordIsVisible}
              passwordLabel={passwordLabel}
            />
            <img
              src={passwordIsVisible ? openEyeIcon : closeEyeIcon}
              alt="Olho para mostrar conteudo da senha"
              className="show-password-icon"
            />
            <ButtonShowPassword
              passwordIsVisible={passwordIsVisible}
              setPasswordIsVisible={setPasswordIsVisible}
            />
          </Box>
          <Box width="80%" m={1} className="password-container">
            <Password
              label="Confirme a Senha"
              passwordIsValid={passwordConfirmIsValid}
              setPassword={setPasswordConfirm}
              passwordIsVisible={passwordIsVisible}
              passwordLabel={passwordLabel}
            />
            <img
              src={passwordIsVisible ? openEyeIcon : closeEyeIcon}
              alt="Olho para mostrar conteudo da senha"
              className="show-password-icon"
            />
            <ButtonShowPassword
              passwordIsVisible={passwordIsVisible}
              setPasswordIsVisible={setPasswordIsVisible}
            />
          </Box>

          {/* Buttons */}
          <Box mt={2} width="80%">
            <Grid
              container
              direction="row"
              justifyContent="center"
              xs={12}
              sm={12}
              lg={12}
            >
              <Box width="100%">
                <Button
                  disabled={isValid}
                  onClick={resetPassword}
                  className="submit-button"
                >
                  Confirmar Senha
                </Button>
              </Box>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};
