import { EstocaLogo } from '../EstocaLogo';
import * as S from './styles';

export const Header = () => {
  return (
    <S.Wrapper>
      <S.Logo to="/">
        <EstocaLogo />
      </S.Logo>
    </S.Wrapper>
  );
};
