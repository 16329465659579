import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import backgroundLoginImage from '../assets/rosebox-BFdSCxmqvYc-unsplash.png';

export const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
    [theme.breakpoints.down(1280)]: {
      flexWrap: 'nowrap',
      height: '90vh',
      flexDirection: 'column',
    },
  },
  hover: {
    '&:hover': { cursor: 'pointer' },
  },
}));

export const BackgroundImage = () => {
  return (
    <Grid>
      <Box
        width="100%"
        sx={{ display: { xs: 'none', sm: 'none', lg: 'flex' } }}
      >
        <img
          src={backgroundLoginImage}
          alt="Imagem ilustrativa de logística"
          style={{ height: '100vh' }}
        />
      </Box>
    </Grid>
  );
};

export const Loading = () => {
  return (
    <div className="lds-ring">
      <div />
      <div />
      <div />
      <div />
    </div>
  );
};
