import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import backIcon from '../assets/backIcon.svg';
import { Email } from '../components/authComponents';
import { BackgroundImage, useStyles } from '../components/styleComponents';
import { AuthContext } from '../contexts/authContext';
import { useDocumentTitle } from '../hooks/use-document-title';
import { useValidEmail } from '../hooks/useAuthHooks';
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from '../utils/constants';

export const RequestCode = () => {
  const classes = useStyles();

  const { email, setEmail, emailIsValid } = useValidEmail('');

  const isValid = !emailIsValid || email.length === 0;

  const history = useHistory();

  const authContext = useContext(AuthContext);

  const sendCodeClicked = async () => {
    try {
      await authContext.sendCode(email);
      toast.success(SUCCESS_MESSAGES.sendConfirmationCode);
      history.push('/forgot-password');
    } catch (err) {
      toast.error(ERROR_MESSAGES.sendConfirmationCode);
    }
  };

  useDocumentTitle('Requisitar código');

  return (
    <Grid
      className={classes.root}
      container
      direction="row"
      alignItems="center"
    >
      <button
        type="button"
        className="backIcon"
        onClick={() => history.goBack()}
      >
        <img src={backIcon} alt="ícone que representa uma seta voltando" />
      </button>
      <BackgroundImage />
      <Grid
        xs={11}
        sm={6}
        lg={6}
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        item
        style={{ marginBottom: '100px', margin: '0 auto' }}
      >
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          style={{ maxWidth: '700px' }}
        >
          {/* Title */}
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              variant="h5"
              style={{
                fontWeight: 'bold',
                marginTop: '15px',
                marginBottom: '20px',
              }}
            >
              Esqueceu sua senha?
            </Typography>
          </Grid>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              style={{
                textAlign: 'center',
                width: '65%',
                marginTop: '15px',
                marginBottom: '20px',
              }}
            >
              Digite seu endereço de email abaixo e nós enviaremos o código para
              a redefinição de senha:
            </Typography>
          </Grid>

          {/* Sign In Form */}
          <Box width="80%" m={2}>
            <Email emailIsValid={emailIsValid} setEmail={setEmail} />
          </Box>

          {/* Buttons */}
          <Box mt={2} width="80%">
            <Grid
              container
              direction="row"
              justifyContent="center"
              xs={12}
              sm={12}
              lg={12}
            >
              <Box width="100%">
                <Button
                  disabled={isValid}
                  onClick={sendCodeClicked}
                  className="submit-button"
                >
                  Mandar código
                </Button>
              </Box>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};
