import { CSSProperties } from '@stitches/react';
import { Link } from 'react-router-dom';

import { styled } from '../../styles/global';

export const headerHeight: CSSProperties['height'] = '3.125rem' as const; // 50px

export const Wrapper = styled('header', {
  zIndex: 3,

  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',

  height: headerHeight,
  paddingX: '1.5rem', // 24px

  backgroundColor: '$white',
  boxShadow: '$header',

  img: {
    pointerEvents: 'none',
  },
});

export const Logo = styled(Link, {
  img: {
    width: '6.25rem', // 100px
    maxHeight: '3.338rem', // 53.4px

    margin: 'auto',

    objectFit: 'contain',
    objectPosition: 'center',
  },
});
