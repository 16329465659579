import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom';

import { AuthIsNotSignedIn, AuthIsSignedIn } from '../contexts/authContext';
import { ChangePassword } from '../pages/ChangePassword';
import { ForgotPassword } from '../pages/ForgotPassword';
import { NewPassword } from '../pages/NewPassword';
import { PageNotFound } from '../pages/PageNotFound';
import { RequestCode } from '../pages/RequestCode';
import { SignIn } from '../pages/SignIn';
import { SignOut } from '../pages/SignOut';
import { UserRedirect } from '../pages/UserRedirect';
import { VerifyCode } from '../pages/VerifyCode';

const PublicRoutes = () => (
  <Router>
    <Switch>
      <Route path="/signin" component={SignIn} />
      <Route path="/verify" component={VerifyCode} />
      <Route path="/recovery-password" component={RequestCode} />
      <Route path="/forgot-password" component={ForgotPassword} />
      <Route path="/new-password" component={NewPassword} />
      <Route path="/404" component={PageNotFound} />
      <Route exact path="/" component={SignIn} />
      <Redirect path="*" to="/" />
    </Switch>
  </Router>
);

const PrivateRoutes = () => (
  <Router>
    <Switch>
      <Route path="/user-redirect" component={UserRedirect} />
      <Route path="/change-password" component={ChangePassword} />
      <Route path="/recovery-password" component={RequestCode} />
      <Route path="/forgot-password" component={ForgotPassword} />
      <Route path="/logout" component={SignOut} />
      <Route path="/404" component={PageNotFound} />
      <Route path="/" component={SignIn} />
      <Redirect path="*" to="/" />
    </Switch>
  </Router>
);

export const Routes = () => {
  return (
    <>
      <AuthIsSignedIn>
        <PrivateRoutes />
      </AuthIsSignedIn>

      <AuthIsNotSignedIn>
        <PublicRoutes />
      </AuthIsNotSignedIn>
    </>
  );
};
