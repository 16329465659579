import sellerLogo from '../../assets/e-commerce.png';
import plataformLogo from '../../assets/plataform.png';
import wmsLogo from '../../assets/wms.png';
import { Header } from '../../components/Header';
import { useDocumentTitle } from '../../hooks/use-document-title';
import { globalStyles } from '../../styles/global';
import * as S from './styles';

const cards = [
  {
    title: 'wms.estoca',
    href: String(process.env.REACT_APP_WMS_URL),
    img: {
      src: wmsLogo,
      alt: 'um ícone de um armazém com 5 caixas de encomendas dentro',
    },
  },
  {
    title: 'seller.estoca',
    href: String(process.env.REACT_APP_DEFAULT_RETURN_URL),
    img: {
      src: sellerLogo,
      alt: 'um ícone de carrinho de compras sendo mostrando dentro de um monitor de computador',
    },
  },
  {
    title: 'plataforma.estoca',
    href: String(process.env.REACT_APP_PLATFORM_URL),
    img: {
      src: plataformLogo,
      alt: 'um ícone de uma caixa embalada',
    },
  },
];

export const UserRedirect = () => {
  globalStyles();

  useDocumentTitle('Redirecionamento');

  return (
    <S.Wrapper>
      <Header />

      <S.Content>
        {cards.map(card => (
          <S.Card key={card.title} href={card.href}>
            <img src={card.img.src} alt={card.img.alt} />
            {card.title}
          </S.Card>
        ))}
      </S.Content>
    </S.Wrapper>
  );
};
