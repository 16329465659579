import {
  PropertyValue,
  createStitches,
  defaultThemeMap,
  globalCss,
} from '@stitches/react';

import { breakpoints } from './media';
import { theme as myTheme } from './theme';

export const {
  styled,
  css,
  keyframes,
  getCssText,
  theme,
  createTheme,
  config,
} = createStitches({
  themeMap: {
    ...defaultThemeMap,
    borderRadius: 'borderRadius',
    border: 'borders',
    borderWidth: 'borders',
    fontStyle: 'fontStyles',
    opacity: 'opacities',
    boxShadow: 'shadows',
    filter: 'shadows',
    padding: 'spacings',
    paddingTop: 'spacings',
    paddingBottom: 'spacings',
    paddingLeft: 'spacings',
    paddingRight: 'spacings',
    margin: 'spacings',
    marginTop: 'spacings',
    marginBottom: 'spacings',
    marginLeft: 'spacings',
    marginRight: 'spacings',
    top: 'spacings',
    bottom: 'spacings',
    left: 'spacings',
    right: 'spacings',
    gap: 'spacings',
    outlineColor: 'colors',
    borderColor: 'colors',
  },
  theme: myTheme,
  utils: {
    marginX: (value: PropertyValue<'margin'>) => ({
      marginLeft: value,
      marginRight: value,
    }),
    marginY: (value: PropertyValue<'margin'>) => ({
      marginTop: value,
      marginBottom: value,
    }),
    paddingX: (value: PropertyValue<'padding'>) => ({
      paddingLeft: value,
      paddingRight: value,
    }),
    paddingY: (value: PropertyValue<'padding'>) => ({
      paddingTop: value,
      paddingBottom: value,
    }),
  },
  media: {
    huge: `(max-width: ${breakpoints.huge}px)`,
    large: `(max-width: ${breakpoints.large}px)`,

    medium: `(max-width: ${breakpoints.medium}px)`,
    small: `(max-width: ${breakpoints.small}px)`,
    xsmall: `(max-width: ${breakpoints.xsmall}px)`,

    isDesktop: `(min-width: ${breakpoints.large}px)`,
    isMobile: `(max-width: ${breakpoints.medium}px)`,
  },
});

export const globalStyles = globalCss({
  '@font-face': [
    {
      fontWeight: 400,
      fontStyle: 'normal',
      fontFamily: 'Open Sans',
      fontDisplay: 'swap',
      src: "url('/fonts/OpenSans-Regular.ttf') format('truetype')",
    },
    {
      fontWeight: 500,
      fontStyle: 'normal',
      fontFamily: 'Open Sans',
      fontDisplay: 'swap',
      src: "url('/fonts/OpenSans-Medium.ttf') format('truetype')",
    },
    {
      fontWeight: 700,
      fontStyle: 'normal',
      fontFamily: 'Open Sans',
      fontDisplay: 'swap',
      src: "url('/fonts/OpenSans-Bold.ttf') format('truetype')",
    },
  ],

  ':root': {
    fontSynthesis: 'none',
    'text-rendering': 'optimizeLegibility',
    '-webkit-font-smoothing': 'antialiased',
    '-moz-osx-font-smoothing': 'grayscale',
    '-webkit-text-size-adjust': '100%',
    '-webkit-tap-highlight-color': 'transparent',
  },

  '*': {
    margin: 0,
    padding: 0,
    boxSizing: 'border-box',
  },

  body: {
    fontStyle: 'normal',
    fontWeight: '$regular',
    fontFamily: '$base',
  },

  button: {
    border: 'none',
    cursor: 'pointer',
    backgroundColor: 'transparent',

    transition: 'all .2s ease-in-out',

    '&:disabled, &[disabled]': {
      cursor: 'not-allowed',
    },
  },

  a: {
    textDecoration: 'none',
    width: 'fit-content',
    height: 'fit-content',
    color: 'inherit',
  },

  'label, input, textarea, button, select, a': {
    fontStyle: 'normal',
    fontWeight: '$regular',
    fontFamily: '$base',
  },
});
