import estocaLogo from '../../assets/estoca-logo.svg';
import { globalStyles } from '../../styles/global';
import * as S from './styles';

export const EstocaLogo = () => {
  globalStyles();

  return (
    <S.Logo
      src={estocaLogo}
      alt="Nome 'estoca' escrito na cor azul, e com hífens nos lados horizontais"
    />
  );
};
