import { Button } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { KeyboardEvent } from 'react';

interface EmailProps {
  emailIsValid: boolean;
  onKeyUp?: (event: KeyboardEvent<HTMLDivElement>) => void;
  setEmail: (email: string) => void;
}

export const Email = ({ emailIsValid, setEmail, onKeyUp }: EmailProps) => {
  return (
    <TextField
      className="input-container"
      fullWidth
      variant="outlined"
      label={emailIsValid ? 'Email' : 'Email inválido'}
      error={!emailIsValid}
      onKeyUp={onKeyUp}
      onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
        setEmail(evt.target.value);
      }}
    />
  );
};

interface PasswordProps {
  label: string;
  passwordIsValid: boolean;
  passwordIsVisible: boolean;
  passwordLabel?:string;
  onKeyUp?: (event: KeyboardEvent<HTMLDivElement>) => void;
  setPassword: (password: string) => void;
}

export const Password = ({
  label,
  passwordIsValid,
  passwordIsVisible,
  passwordLabel,
  setPassword,
  onKeyUp,
}: PasswordProps) => {
  return (
    <TextField
      fullWidth
      className="input-container"
      type={passwordIsVisible ? 'text' : 'password'}
      variant="outlined"
      label={passwordIsValid ? label : passwordLabel}
      error={!passwordIsValid}
      onKeyUp={onKeyUp}
      onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
        setPassword(evt.target.value);
      }}
    />
  );
};

interface TotpCodeProps {
  label: string;
  totpIsValid: boolean;
  isActive: boolean;
  setTotp: (code: string) => void;
}

export const TotpCode = ({
  label,
  isActive,
  totpIsValid,
  setTotp,
}: TotpCodeProps) => {
  let className = 'totp';
  if (isActive) {
    className += ' totp-active';
  }
  return (
    <TextField
      fullWidth
      type="text"
      variant="outlined"
      className={className}
      label={totpIsValid ? label : 'Mínimo de 8 caracteres'}
      error={!totpIsValid}
      onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
        setTotp(evt.target.value);
      }}
    />
  );
};

interface UsernameProps {
  usernameIsValid: boolean;
  setUsername: (username: string) => void;
}

export const Username = ({ usernameIsValid, setUsername }: UsernameProps) => {
  return (
    <TextField
      fullWidth
      variant="outlined"
      label={usernameIsValid ? 'Username' : 'Mínimo de 8 caracteres'}
      error={!usernameIsValid}
      onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
        setUsername(evt.target.value);
      }}
    />
  );
};

interface CodeProps {
  codeIsValid: boolean;
  setCode: (code: string) => void;
}

export const Code = ({ codeIsValid, setCode }: CodeProps) => {
  return (
    <TextField
      fullWidth
      variant="outlined"
      label={codeIsValid ? 'Code' : 'Mínimo de 6 caracteres'}
      error={!codeIsValid}
      onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
        setCode(evt.target.value);
      }}
    />
  );
};

interface ButtonShowPasswordProps {
  passwordIsVisible: boolean;
  setPasswordIsVisible: (isVisible: boolean) => void;
}

export const ButtonShowPassword = ({
  passwordIsVisible,
  setPasswordIsVisible,
}: ButtonShowPasswordProps) => {
  return (
    <Button
      onClick={() => setPasswordIsVisible(!passwordIsVisible)}
      color="secondary"
      variant="contained"
      className="show-password-button"
    />
  );
};
