import { useContext, useEffect } from 'react';
import { toast } from 'react-toastify';

import { AuthContext } from '../contexts/authContext';
import { useDocumentTitle } from '../hooks/use-document-title';
import { Cookie } from '../libs/cookie';
import { ERROR_MESSAGES } from '../utils/constants';

export const SignOut = () => {
  const authContext = useContext(AuthContext);
  const hostUrl = String(process.env.REACT_APP_PLATFORM_URL);

  useDocumentTitle('Sair');

  const logout = async () => {
    try {
      await authContext.signOut();
      window.localStorage.removeItem('refreshToken');
      window.localStorage.removeItem('accessToken');
      Cookie.erase('refreshToken');
      Cookie.erase('accessToken');
      Cookie.erase('idToken');
      Cookie.erase('sessionid');
      Cookie.erase('estocacsrftoken');
      window.location.href = `${hostUrl}/logout`;
    } catch (err) {
      toast.error(ERROR_MESSAGES.signOut);
    }
  };

  useEffect(() => {
    logout();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};
