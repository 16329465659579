import './App.css';

import { ToastContainer } from 'react-toastify';

import AuthProvider from './contexts/authContext';
import { Routes } from './routes';

import 'react-toastify/dist/ReactToastify.css';

export const App = () => (
  <AuthProvider>
    <Routes />
    <ToastContainer />
  </AuthProvider>
);
