const SUPPORT_DESCRIPTION = 'por favor contatar o suporte!';
const ERROR_DESCRIPTION = `Caso o erro persista, ${SUPPORT_DESCRIPTION}`;

export const ERROR_MESSAGES = {
  changePassword: `Erro ao alterar a sua senha, verifique se os dados estão corretos. ${ERROR_DESCRIPTION}`,
  resetPassword: `Erro ao redefinir a sua senha, verifique se os dados estão corretos. ${ERROR_DESCRIPTION}`,
  sendConfirmationCode: `Erro ao enviar o código de confirmação, verifique se os dados estão corretos. ${ERROR_DESCRIPTION}`,
  verifyConfirmationCode: `Erro ao verificar o código de confirmação, verifique se os dados estão corretos. ${ERROR_DESCRIPTION}`,
  signIn: `Erro ao fazer login, verifique se os dados estão corretos. ${ERROR_DESCRIPTION}`,
  signOut: `Erro ao sair da aplicação. ${ERROR_DESCRIPTION}`,
  invalidUserType: `Erro de identificação do usuário, ${SUPPORT_DESCRIPTION}`,
  userNotFound: `Usuário não encontrado na plataforma Estoca, ${SUPPORT_DESCRIPTION}`,
} as const;

export const SUCCESS_MESSAGES = {
  changePassword: 'A sua senha foi redefinida com sucesso!',
  resetPassword: 'A sua senha foi redefinida com sucesso!',
  sendConfirmationCode: 'O seu código de confirmação foi enviado com sucesso!',
  signIn: 'Login realizado com sucesso!',
} as const;
