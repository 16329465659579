/* eslint-disable eqeqeq */
/* eslint-disable no-plusplus */
export const Cookie = {
  set(name: string, value: string, days: number) {
    let domain;
    let domainParts;
    let date;
    let expires;

    if (days) {
      date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = `; expires=${date.toUTCString()}`;
    } else {
      expires = '';
    }

    if (window.location.host.split('.').length === 1) {
      // no "." in a domain - it's localhost or something similar
      document.cookie = `${name}=${value}${expires}; path=/`;
    } else {
      // Remember the cookie on all subdomains.
      //
      // Start with trying to set cookie to the top domain.
      // (example: if user is on foo.com, try to set
      //  cookie to domain ".com")
      //
      // If the cookie will not be set, it means ".com"
      // is a top level domain and we need to
      // set the cookie to ".foo.com"
      domainParts = window.location.host.split('.');
      domainParts.shift();
      domain = `.${domainParts.join('.')}`;

      document.cookie = `${name}=${value}${expires}; path=/; domain=${domain}`;

      // check if cookie was successfuly set to the given domain
      // (otherwise it was a Top-Level Domain)
      if (Cookie.get(name) == null || Cookie.get(name) != value) {
        // append "." to current domain
        domain = `.${window.location.host}`;
        document.cookie = `${name}=${value}${expires}; path=/; domain=${domain}`;
      }
    }
  },

  get(name: string) {
    const nameEQ = `${name}=`;
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1, c.length);
      }

      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  },

  erase(name: string) {
    Cookie.set(name, '', -1);
  },
};
