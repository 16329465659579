import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useHistory } from 'react-router-dom';

import backIcon from '../assets/backIcon.svg';
import { BackgroundImage } from '../components/styleComponents';
import { useDocumentTitle } from '../hooks/use-document-title';

const useStyles = makeStyles({
  root: {
    height: '100vh',
  },
});

export const PageNotFound = () => {
  const classes = useStyles();
  const history = useHistory();

  useDocumentTitle('Página não encontrada');

  return (
    <Grid
      className={classes.root}
      container
      direction="row"
      alignItems="center"
    >
      <button
        type="button"
        className="backIcon"
        onClick={() => history.goBack()}
      >
        <img src={backIcon} alt="ícone que representa uma seta voltando" />
      </button>
      <BackgroundImage />
      <Grid
        xs={11}
        sm={6}
        lg={6}
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        item
        style={{ marginBottom: '100px', margin: '0 auto' }}
      >
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          style={{ maxWidth: '700px' }}
        >
          {/* Title */}
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              variant="h5"
              style={{
                fontWeight: 'bold',
                marginTop: '15px',
                marginBottom: '20px',
              }}
            >
              Página não encontrada
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
