export function getUrlQuery(parameter: string) {
  let search = window.location.href.split('?')[1];
  if (search) {
    search = search.replace('#/', '');
    const result = {} as { [key: string]: string };
    const keyValuePairs = search.split('&');
    keyValuePairs.forEach(
      (key: string, index: number, keyValuePair: string[]) => {
        keyValuePair = key.split('=');
        result[decodeURIComponent(keyValuePair[0])] =
          decodeURIComponent(keyValuePair[1]) || '';
      }
    );
    return result[parameter];
  }
}
