import { useEffect, useState } from 'react';
import * as yup from 'yup';

export const useValidEmail = (initialValue: string) => {
  const [email, setEmail] = useState(initialValue);
  const [emailIsValid, setEmailIsValid] = useState(true);

  useEffect(() => {
    const emailSchema = yup.object().shape({
      email: yup.string().email().required(),
    });

    if (email.length === 0) {
      setEmailIsValid(true);
      return;
    }

    const isValid = emailSchema.isValidSync({ email });

    setEmailIsValid(isValid);
  }, [email]);

  return { email, setEmail, emailIsValid };
};

export const useValidPassword = (initialValue: string) => {
  const [password, setPassword] = useState(initialValue);
  const [passwordIsValid, setPasswordIsValid] = useState(true);
  const [passwordLabel, setPasswordLabel] = useState('');
  useEffect(() => {

    const fetchPassword = async () => {
      const passwordSchema = yup.object().shape({
        password: yup.string()
        .min(8, 'Mínimo 8 caracteres')
        .matches(/[0-9]/, 'Mínimo 1 número')
        .matches(/[a-z]/, 'Mínimo 1 letra minúscula')
        .matches(/[A-Z]/, 'Mínimo 1 letra maiúscula')
        .matches(/^(?!\s+)(?!.*\s+$)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/, 'Mínimo 1 caractere especial')
        .required(),
        
      });
  
      if (password.length === 0) {
        setPasswordIsValid(true);
        return;
      }
  
      const isValid = passwordSchema.isValidSync({ password });
      await passwordSchema.validate({password});
      setPasswordIsValid(isValid);
    };
    
    fetchPassword()
    .catch((e) => {
      setPasswordIsValid(false);
      setPasswordLabel(String(e).replace('ValidationError: ', ''));
    });;

  }, [password]);

  return { password, setPassword, passwordIsValid, passwordLabel };
};

export const useValidUsername = (initialValue: string) => {
  const [username, setUsername] = useState(initialValue);
  const [usernameIsValid, setUsernameIsValid] = useState(true);

  useEffect(() => {
    const usernameSchema = yup.object().shape({
      username: yup.string().min(8).required(),
    });

    if (username.length === 0) {
      setUsernameIsValid(true);
      return;
    }

    const isValid = usernameSchema.isValidSync({ username });

    setUsernameIsValid(isValid);
  }, [username]);

  return { username, setUsername, usernameIsValid };
};

export const useValidCode = (initialValue: string) => {
  const [code, setCode] = useState(initialValue);
  const [codeIsValid, setCodeIsValid] = useState(true);

  useEffect(() => {
    const codeSchema = yup.object().shape({
      code: yup.string().min(6).required(),
    });

    if (code.length === 0) {
      setCodeIsValid(true);
      return;
    }

    const isValid = codeSchema.isValidSync({ code });

    setCodeIsValid(isValid);
  }, [code]);

  return { code, setCode, codeIsValid };
};

export const useValidTotp = (initialValue: string) => {
  const [totp, setTotp] = useState(initialValue);
  const [totpIsValid, settotpIsValid] = useState(true);

  useEffect(() => {
    const codeSchema = yup.object().shape({
      code: yup.string().min(6).required(),
    });

    if (totp.length === 0) {
      settotpIsValid(true);
      return;
    }

    const isValid = codeSchema.isValidSync({ totp });

    settotpIsValid(isValid);
  }, [totp]);

  return { totp, setTotp, totpIsValid };
};

export const usePasswordIsVisible = () => {
  const [passwordIsVisible, setPasswordIsVisible] = useState(true);

  useEffect(() => {
    setPasswordIsVisible(!passwordIsVisible);
  }, []);

  return { passwordIsVisible, setPasswordIsVisible };
};
